import { UiTab } from "./ui-tab";

export class UiProfilePage {
    id: string;
    name: string;
    uiProfileId: string;
    urlPath: string;
    informationalBadge: MenuInformationalBadge;
    userTypeIds: string[];
    tabs: UiTab[];
    navigation: UiProfilePageNavigationType;
    templateName: string;
    visibilityCondition: string;
    external: boolean;
    externalUrl: string;
    type: UiProfilePageType;
    showStoreCatalog?: boolean;
    storeCatalogLayoutMode?: StoreCatalogLayoutMode;
    controlBarTemplateName: string;
    showSubscriptionsCustomers?: boolean;
    showSubscriptionsPartners?: boolean;
}

export enum MenuInformationalBadge {
    ALERT_COUNT = 'ALERT_COUNT',
    WORK_SESSION_COUNT = 'WORK_SESSION_COUNT',
    ACTION_COUNT = 'ACTION_COUNT',
    ACTION_COUNT_BY_PRIORITY = 'ACTION_COUNT_BY_PRIORITY'
}

export enum UiProfilePageNavigationType {
    SIDEBAR_MENU = 'SIDEBAR_MENU',
    LINK = 'LINK',
    TOPBAR_LEFT = 'TOPBAR_LEFT',
    TOPBAR_RIGHT = 'TOPBAR_RIGHT',
    TOPBAR = 'TOPBAR',
    USER_MENU = 'USER_MENU'
}

export enum UiProfilePageType {
    CUSTOM = 'CUSTOM',
    STORE = 'STORE',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
    PAYMENTS = 'PAYMENTS'
}

export enum StoreCatalogLayoutMode {
    GRID = 'GRID',
    LIST = 'LIST'
}